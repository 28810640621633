<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Utilisation des SIT</h1>
        </div>
        <div class="k-page-body">
            <TableListSit @item-clicked="onSITSelected" />
        </div>
        <ModalListSit :dataSourceTypeId="selectedDataSourceTypeId" />
    </div>
</template>

<script>
import TableListSit from "@/components/model/listsit/TableListSit";
import ModalListSit from "@/components/model/listsit/ModalListSit";
export default {
    components: {
        TableListSit,
        ModalListSit,
    },

    data: function() {
        return {
            selectedDataSourceTypeId: null,
        };
    },

    methods: {
        async fetchDatasourcesTypes() {
            this.dataSourceTypes = await this.$store.dispatch(
                "datasource/getDataSourceTypes",
            );
        },

        onSITSelected(id) {
            this.selectedDataSourceTypeId = id;
            this.$bvModal.show("ModalListSit");
        },
    },
};
</script>
