<template>
    <b-modal
        id="ModalListSit"
        size="xl"
        :title="`Détails des utilisations`"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
        scrollable
    >
        <div>
            <b-table
                class="text-break text-center"
                :items="sources"
                :fields="fields"
                show-empty
                empty-text="Pas de source de données"
                hover
                tbody-tr-class="pointer"
                striped
                @row-clicked="goToDataSource"
            >
                <template #cell(createdAt)="data">
                    {{ data.item.createdAt | dateFromISO(true) }}
                </template>
                <template #cell(projectName)="data">
                    {{ data.item.Project.name }}
                </template>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Chargement des données...</strong>
                    </div>
                </template>
            </b-table>
            <div>
                <b-pagination
                    class="m-auto"
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="totalRows"
                    align="center"
                    size="sm"
                    first-number
                    last-number
                />
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    components: {},

    props: {
        dataSourceTypeId: { type: [String, Number] },
    },

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            sources: [],
            fields: [
                {
                    key: "id",
                    label: "#",
                    sortable: true,
                    tdClass: "",
                },
                {
                    key: "projectName",
                    label: "Nom du projet",
                    sortable: true,
                    tdClass: "text-left",
                },
                {
                    key: "name",
                    label: "Nom de la source de données",
                    sortable: true,
                    tdClass: "text-left",
                },
                {
                    key: "createdAt",
                    label: "Création",
                    sortable: true,
                },
            ],
            filter: "",
        };
    },

    watch: {
        async dataSourceTypeId() {
            await this.fetchDatasources();
        },
    },

    methods: {
        async fetchDatasources() {
            this.sources = await this.$store.dispatch(
                "datasource/getDataSourcesForDataSourceTypes",
                {
                    dataSourceTypeId: this.dataSourceTypeId,
                },
            );
        },

        onRowClicked(dataSourceType) {
            this.$emit("item-clicked", dataSourceType.id);
        },

        goToDataSource(DataSource) {
            this.$router.push({
                name: "AdminDataSource",
                params: {
                    datasourceId: DataSource.id,
                    projectId: DataSource.Project.id,
                },
            });
        },
    },

    async beforeMount() {
        if (this.dataSourceTypeId !== null) {
            await this.fetchDatasources();
        }
    },
};
</script>
