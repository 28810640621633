<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <div class="flex-grow-1 mr-4">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher un SIT"
                        v-model="search"
                        debounce="500"
                    />
                </b-input-group>
            </div>

            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    SIT par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="5">5</b-form-radio>
                        <b-form-radio :value="10">10</b-form-radio>
                        <b-form-radio :value="20">20</b-form-radio>
                        <b-form-radio :value="50">50</b-form-radio>
                        <b-form-radio :value="100">100</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <b-table
            class="text-break mt-2 text-center"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :per-page="perPage"
            sort-by="name"
            :sort-desc="false"
            show-empty
            empty-text="Aucun SIT"
            empty-filtered-text="Aucun SIT trouvé"
            stacked="md"
            hover
            tbody-tr-class="pointer"
            @row-clicked="onRowClicked"
            striped
        >
            <template #cell(isObsolete)="data">
                <b-icon
                    :icon="data.item.isObsolete ? 'x-lg' : 'check'"
                    :variant="data.item.isObsolete ? 'danger' : 'kalkin-2'"
                ></b-icon>
            </template>
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
export default {
    components: {},

    data: function() {
        return {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            fields: [
                {
                    key: "id",
                    label: "#",
                    sortable: true,
                    tdClass: "text-nowrap",
                },
                {
                    key: "name",
                    label: "Nom",
                    sortable: true,
                    tdClass: "text-left",
                },
                {
                    key: "type",
                    label: "Type",
                    sortable: true,
                },
                {
                    key: "nomenclature",
                    label: "Nomenclature",
                    sortable: true,
                    tdClass: "text-nowrap",
                },
                {
                    key: "sourceCount",
                    label: "Utilisations",
                    sortable: true,
                    tdClass: "text-nowrap",
                },
                {
                    key: "isObsolete",
                    label: "Actif",
                    sortable: true,
                    tdClass: "text-nowrap",
                },
            ],
            search: this.$route.query.search || "",
        };
    },

    computed: {
        filter() {
            let filter = "";
            if (this.search) {
                filter += `id startsWith ${this.search}`;
            }
            return filter.length > 0 ? filter : undefined;
        },
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "datasource/countFSPDataSourceTypes",
                {
                    filter: this.filter,
                },
            );
        },

        async dataProvider(ctx) {
            const dst = await this.$store.dispatch(
                "datasource/getFSPDataSourceTypes",
                {
                    filter: this.filter,
                    sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                    limit: this.perPage,
                    offset: this.perPage * (this.currentPage - 1),
                },
            );
            return dst;
        },

        onRowClicked(dataSourceType) {
            this.$emit("item-clicked", dataSourceType.id);
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
